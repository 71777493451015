.localizations--wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 20px 0 10px 0;
    background-color: var(--grey2);
}

.localizations--mainContainer {
    width: clamp(850px, 62.4%, 1600px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.localizations--linkWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.localizations--linkContainer {
    width: 16%;
    min-width: 190px;
    height: 38px;
    border-radius: 25px;
}

.localizations--linkContainer:hover {
    background-color: var(--red);
} 

.localizations--linkContainer a {
    display: flex;
    justify-content:center;
    align-items:center;
    width: 100%;
    height: 100%;
    padding: 15px 20px;
    border-radius: 25px;
    text-decoration: none;
    font-size: 85%;
    font-weight: normal;
    white-space: nowrap;
    line-height: normal;
    vertical-align: middle;
    color: var(--fontGrey);
}

.localizations--linkContainer a:hover {
    color: var(--white);
}