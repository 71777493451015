.about--wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    position: relative;
}

.about--baner {
    width: 100%;
    position: relative;
}

.about--baner > img {
    width: 100%;
    object-fit: cover;
}

.about--baner__filter {
    width: 100%;
    height: calc(100% - 4px);
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--blackBaner);
    display: flex;
    justify-content: center;
    align-items: center;
}

.about--baner__filter > h1 {
    font-family: 'Bebas Neue', cursive;
    font-size: clamp(45px, 4.6vw, 88px);
    font-weight: normal;
    color: var(--white);
}

.about--baner__filter > h1:after {
    content: ' DO WYNAJĘCIA';
    color: var(--red);
}

.about--mainContent {
    width: clamp(850px, 62.4%, 1600px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    position: relative;
}

.about--firstDescr {
    margin-top: 70px;
}

.about--firstDescr p:nth-child(5) {
    margin-bottom: 30px;
}

.about--secDescr p:first-child {
    margin: 30px 0;
}

.about--secDescr > p:last-child {
    margin: 30px 0;
}

.about__mainContent--after:after{
    content: ' ';
    width: 40%;
    height: 2px;
    display: inline-block;
    border-top: 2px solid var(--grey2);
    position: absolute;
    bottom: 0;
    left: 0;
}

.about--mainContent > p {
    display: inline-block;
    text-align: justify;
    line-height: 2em;
    font-size: 1.1em;
    font-weight: 300;
    color: var(--fontGrey);
}

.about__mainContent--list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.about__list--line {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.about__list--line > img {
    width: 29px;
    height: 29px;
    object-fit: cover;
}

.about__list--line > p {
    display: inline-block;
    width: calc(100% - 49px);
    font-size: 18px;
    font-weight: 500;
    text-align: justify;
    letter-spacing: 0.1em; 
    color: var(--fontGrey);
}

.about__benefits--wrapper {
    width: clamp(850px, 62.4%, 1600px);
}

.about--afterBenefits {
    width: clamp(850px, 62.4%, 1600px);
    margin: 80px 0 130px 0;
    
}

.about__afterBenefits--list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 3px;
}

.about__afterBenefits--list > p {
    display: inline-block;
    align-self: flex-start;
    margin: 30px 0 30px 49px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.1em; 
    color: var(--black); 
}

@media only screen and (max-width: 949px) {

    .about--baner__filter > h1 {
        font-size: 1.5em;
    }

    .about--mainContent,
    .about--afterBenefits {
        width: 90%;
        margin: 0 0 20px 0;
    }

    .about__benefits--wrapper {
        width: 100%;
        padding: 0 15px;
    }

    .about--firstDescr {
        margin: 0;
    }

    .about--secDescr p:first-child {
        margin: 0;
    }

    .about--secDescr > p:last-child {
        margin: 0;
    }

    .about__mainContent--list {
        gap: 10px;
    }

    .about__list--line {
        gap: 10px;
        justify-content: flex-start;
    }

    .about__list--line > img {
        width: 15px;
        height: 15px;
    }

    .about__list--line > p {
        font-size: 0.8em;
        letter-spacing: 0;
    }

    .about__afterBenefits--list > p {
        margin: 10px 10px 10px 30px;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0;
        color: var(--black);
    }
}

@media only screen and (max-width: 599px) {
    .about--mainContent > p {
        font-size: 0.8em;
    } 
}

