.order--wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
  position: relative;
}

.order--baner {
  width: 100%;
  position: relative;
}

.order--baner > img {
  width: 100%;
  object-fit: cover;
}

.order--baner__filter {
  width: 100%;
  height: calc(100% - 4px);
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--blackBaner);
  display: flex;
  justify-content: center;
  align-items: center;
}

.order--baner__filter > h1 {
  font-family: "Bebas Neue", cursive;
  font-size: clamp(45px, 4.6vw, 88px);
  font-weight: normal;
  color: var(--white);
}

.order--baner__filter > h1:after {
  content: " ON-LINE";
  color: var(--red);
}

.order--mainContent {
  width: clamp(850px, 62.4%, 1600px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: relative;
  margin: 30px 0;
}

.order__mainContent--header {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.order__mainContent--header > h3 {
  align-self: flex-start;
  font-size: 20px;
  font-weight: 600;
}

.order__mainContent--header > h3:after {
  content: " ON-LINE";
  font-size: 20px;
  font-weight: 600;
  color: var(--red);
}

.mainContent__header--info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.mainContent__header--infoWrapper {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.mainContent__header--infoWrapper > div {
  width: max-content;
  height: 60px;
  padding: 10px 20px;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.mainContent__header--lastInfo {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 30px;
}

.bell__container {
  width: 65px;
  height: 65px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 15px var(--bellShadow);
}

.bell__ico {
  font-size: 40px;
  color: var(--bellColor);
}

.mainContent__header--description {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
}

.order--form {
  width: 100%;
}

.next__button--wrapper {
  width: 100%;
  height: 100px;
  background-color: var(--red);
  color: var(--white);
  margin: 90px 0 30px 0;
  padding: 10px 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.order--nextButton {
  cursor: pointer;
  background: none;
  border: none;
  font-family: "Roboto", sans-serif;
  font-size: 2em;
  font-weight: 600;
  color: var(--white);
}

@media only screen and (max-width: 949px) {
  .order--baner__filter > h1 {
    font-size: 1.5em;
  }

  .order--mainContent {
    width: 100%;
  }

  .order__mainContent--header {
    padding: 0 20px;
  }

  .bell__container {
    display: none;
  }

  .mainContent__header--description {
    width: 100%;
    text-align: justify;
  }
}

@media only screen and (max-width: 599px) {
  .form__steps--first > div:nth-child(2)::after,
  .form__steps--first > div:nth-child(3)::after,
  .form__steps--first > div:nth-child(4)::after,
  .form__steps--first > div:nth-child(5)::after,
  .form__steps--secound > div:nth-child(2)::after,
  .form__steps--secound > div:nth-child(3)::after,
  .form__steps--secound > div:nth-child(4)::after,
  .form__steps--secound > div:nth-child(5)::after,
  .form__steps--third > div:nth-child(2)::after,
  .form__steps--third > div:nth-child(3)::after,
  .form__steps--third > div:nth-child(4)::after,
  .form__steps--third > div:nth-child(5)::after,
  .form__steps--fourth > div:nth-child(2)::after,
  .form__steps--fourth > div:nth-child(3)::after,
  .form__steps--fourth > div:nth-child(4)::after,
  .form__steps--fourth > div:nth-child(5)::after {
    display: none;
  }

  .form__formCabins--wrapper {
    padding: 0 10px;
  }

  .form__steps--first > div > p:first-child,
  .form__steps--secound > div > p:first-child,
  .form__steps--third > div > p:first-child,
  .form__steps--fourth > div > p:first-child {
    font-size: 5vw !important;
  }

  .form__steps--first > div > p:last-child,
  .form__steps--secound > div > p:last-child,
  .form__steps--third > div > p:last-child,
  .form__steps--fourth > div > p:last-child {
    display: none;
  }

  .mainContent__header--infoWrapper {
    flex-wrap: wrap;
  }

  .order--mainContent {
      margin-top: 0;
  }
}
