.formSummary--wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
}

.form__steps--third {
    width: 100%;
    aspect-ratio: 8;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 30px;
}

.form__steps--third > div {
    width: calc((100% / 6) - 3px);
    height: 100%;
    background-color: var(--grey2);
    position: relative;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2% 10px;
    text-align: center;    
}

.form__steps--third > div:nth-child(1) {
    background: var(--pageBackground);
    background: linear-gradient(90deg, var(--pageBackground) 25%, var(--grey2) 100%);
}

.form__steps--third > div:nth-child(6) {
    background: var(--pageBackground);
    background: linear-gradient(270deg, var(--pageBackground) 25%, var(--grey2) 100%);
}

.form__steps--third > div:nth-child(2)::after,
.form__steps--third > div:nth-child(3)::after,
.form__steps--third > div:nth-child(4)::after,
.form__steps--third > div:nth-child(5)::after {
    content: ' ';
    width: 15%;
    aspect-ratio: 1;
    background-color: var(--grey2);
    position: absolute;
    top: 40%;
    right: -8.5%;
    z-index: 1;
    transform: rotate(45deg);
    border-top: 3px solid var(--white);
    border-right: 3px solid var(--white);
}

.form__steps--third > div:nth-child(4),
.form__steps--third > div:nth-child(4)::after {
    background-color: var(--red);
    color: var(--white);
}

.form__steps--third > div > p:first-child {
    font-size: 30px;
    font-weight: bold;
}

.form__steps--third > div > p:last-child {
    font-size: clamp(12px, 1.1vw, 16px);
    white-space: nowrap;
}

.formSummary--content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 30px 0  20px 0;
}

.formSummary--content > h3 {
    align-self: flex-start;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
}

.formSummary--content > h3:after {
    content: ' ZAMÓWIENIA:';
    font-size: 20px;
    font-weight: 600;
    color: var(--red);
}

.formSummary__content--wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 30px 0 20px 0;
}

.formSummary--orderSummary,
.formSummary--personalData {
    width: 48%;
    padding: 6%;
    background-color: var(--white);
    border: 1px solid var(--grey2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 30px;
}

.formSummary--orderSummary > div,
.formSummary--personalData > div,
.formSummary__correspAdress--wrapper > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
}

.formSummary--orderSummary > div > label,
.formSummary--personalData > div > label,
.formSummary__correspAdress--wrapper > div > label {
    font-size: 1.5em;
    font-weight: bold;
}

.formSummary--orderSummary > div > p,
.formSummary--personalData > div > p,
.formSummary__correspAdress--wrapper > div > p {
    font-size: 1.2em;
}

.formSummary__correspAdress--header {
    display: flex;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 30px 0;
}

.formSummary--checkedIcon {
    color: var(--red);
}

.formSummary__correspAdress--header > p {
    color: var(--red) !important;
    font-weight: bold !important;
}

.formSummary--price {
    width: 100% !important;
    margin: 40px 0 20px 0;
    gap: 15px !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
}

.formSummary--price > h3 {
    font-size: 2.5em;
    font-weight: 600;
    color: var(--red);
}

.formSummary__price--price {
    width: 80%;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--grey2);
    border-radius: 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1.1em;
    margin: 10px 0;
    background: var(--priceGradient);
}

.formSummary__price--price p:first-of-type {
    font-size: 2em;
    margin: 0 5px;
    font-weight: bold;
}

.formSummary__price--price p:last-of-type {
    font-size: 1.5em;
    font-weight: normal;
}

.formSummary__price--price > div:first-of-type {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.formSummary__price--price > div:last-of-type {
    width: max-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.formSummary__price--price > div > svg {
    color: var(--red);
}

@media only screen and (max-width: 949px) {
    .formSummary--wrapper {
        padding: 0 20px;
    }

    .form__steps--third > div:nth-child(1),
    .form__steps--third > div:nth-child(6) {
        display: none;
    }

    .form__steps--third > div {
        width: calc(25% - 3px);
    }

    .formSummary__content--wrapper {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
    }

    .formSummary--orderSummary, 
    .formSummary--personalData {
        width: 100%;
    }
}

@media only screen and (max-width: 599px) {


    .next__button--wrapper {
        justify-content: flex-end
    }

    .next__button--wrapper p {
        display: none;
    }

    .formSummary--orderSummary > div > label, 
    .formSummary--personalData > div > label, .formSummary__correspAdress--wrapper > div > label {
        font-size: 1em;
    }

    .formSummary--price {
        flex-direction: column !important;
    }

    .formSummary__price--price {
        width: 100%;
    }

    .formSummary__price--price > div:first-of-type {
        display: none;
    }

}