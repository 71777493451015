.red_baner--wrapper {
    width: 100%;
    background-color: var(--red);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: nowrap;
    padding: 5% 3%;
}

.red_baner--icon {
    color: var(--white);
    font-size: 1.2em;
    margin-right: 5px;
}

.red_baner--wrapper > a {
    font-size: 0.7em;
    line-height: 2em;
    font-weight: 600;
    color: var(--white);
    white-space: nowrap;
    text-decoration: none;
}

