.map {
    width: 100%;
    height: 100%;
}

.map--loading {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: var(--whiteTransp);
}

.mapContainer--container {
    width: 100%;
    height: 100%;
    border-radius: 8px;
}