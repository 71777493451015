.navigation--wrapper {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    z-index: 1;
}

.navigation__wrapper--sticky {
    height: clamp(100px, 6vw , 120px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: var(--pageBackground);
    border-bottom: 2px solid var(--grey2);
}

.navigation--mainContainer {
    width: clamp(850px, 62.4%, 1600px);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.navigation--logoWrapper {
    width: 26.3%;
}

.navigation--logoWrapper img {
    width: 100%;
    object-fit: cover;
} 

.navigation--linksWrapper {
    width: calc(50% + 60px);
    height: 43px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
}

.navigation--mainLinks {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.navigation--linkContainer {
    width: max-content;
    min-width: 75px;
    height: 38px;
    border-radius: 25px;
}

.navigation--linkContainer:hover {
    background-color: var(--red);
} 

.navigation--linkContainer a {
    display: flex;
    justify-content:center;
    align-items:center;
    width: 100%;
    height: 100%;
    padding: 15px 20px;
    border-radius: 25px;
    text-decoration: none;
    font-size: 100%;
    font-weight: normal;
    white-space: nowrap;
    line-height: normal;
    vertical-align: middle;
    color: var(--fontGrey);
}

.navigation--linkContainer a:hover {
    color: var(--white);
}

.dropdownLink {
    position: relative;
    cursor: pointer;
}

.dropdownLink > a > p::after {
    content: '';
    position: relative;
    width: 0; 
    height: 0; 
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 11px solid var(--white);
    top: 16px;
    left: 5px;
}

.dropdownLink:hover > a > p {
    color: var(--white);
}

.dropdownLink:hover .dropdownLink--bridge {
    display: block;
}

.dropdownLink--bridge {
    display: none;
    width: 100%;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: initial;
}

.dropdown--linksContainer {
    width: max-content;
    border: 1px solid var(--grey2); 
    position: absolute;
    top: 50px;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--pageBackground);
    z-index: 2;
}

.dropdown--linkContainer {
    width: 100%;
    padding: 0;
    border-radius: 0;
}

.dropdown--linkContainer:hover {
    background-color: var(--red);
}

.dropdown--linkContainer > a > p {
    width: 100%;
    text-align: left;
}

.navigation__orderLink--wrapper {
    min-width: 220px;
    height: 90px;
    text-align: center;
    white-space: nowrap; 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.navigation__orderLink--toilets {
    width: 100%;
    height: 49%;
    background-color: var(--blue); 
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
}

.navigation__orderLink--asenization {
    width: 100%;
    height: 49%;
    background-color: var(--red); 
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
}

.navigation__orderLink--toilets > a,
.navigation__orderLink--asenization > a {
    font-weight: bold;
    font-size: 1.8em;
    text-decoration: none;
    color: var(--white);
}


/* temponary value: */
.navigation__orderLink--asenization > p {
    font-weight: bold;
    font-size: 1.8em;
    text-decoration: none;
    color: var(--white);
}

.navigation__orderLink__asenization--soon {
    background-color: var(--yellow);
    position: absolute;
    right: -36px;
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
    width: 100px;
}

.navigation__orderLink__asenization--soon>p {
    color: var(--red);
    font-weight: 700;
    text-align: center;
}
/* end */

.navigation__mobile--wrapper {
    display: none;
}

@media only screen and (max-width: 949px) {

    .navigation--mainContainer {
        width: 100%;
        padding: 20px;
        flex-direction:  row-reverse;
        justify-content: space-evenly;
        position: relative;
        z-index: 1;
    }

    .navigation__wrapper--sticky {
        width: 100%;
        height: 200px;
        position: relative;
    }

    .navigation--logoWrapper {
        width: 70%;
    }


    .navigation--linksWrapper {
        display: none;
    }

    .navigation__mobile--wrapper {
        display: block;
    }

    .hamburger {
        width: 60px;
        height: 60px;
    }

    .mobNav--wrapper {
        width: 100%;
        height: 100vh;
        position: absolute;
        bottom: calc(-100vh + 14px);
        left: 0;
        background-color: var(--blackTransp);
        z-index: -2;
    }

    .mobNav--links {
        width: 100%;        
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
    }

    .mobNav--linkWrapper {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 2px solid var(--fontGrey);
        background-color: var(--grey2);
    }

    .mobNav--linkWrapper:nth-child(5) {
        border-bottom: none;
        background-color: var(--blue);
    }

    .mobNav--linkWrapper:nth-child(6) {
        border-bottom: none;
        background-color: var(--red);
    }

    .mobNav--linkWrapper:nth-child(5) > a,
    .mobNav--linkWrapper:nth-child(6) > a {
        color: var(--white);
        font-weight: bold;
    }

    .mobNav--linkWrapper:nth-child(6) {
        position: relative;
    }

    .navigation__orderLink__asenization--soon {
        right: 10px;
        width: 150px;
        transform: rotate(-10deg);
        font-size: 1.6em;
    }

    .mobNav--linkWrapper > a,
    .mobNav--linkWrapper > p {
        width: 100%;
        height: 100%;
        color: var(--fontGrey);
        text-decoration: none;
        font-size: 1.7em;
        text-align: center;
        line-height: 2.5;
    }
    
    .mobNav--dropdownWrapper {
        width:100%;
    }

    .dropdown--linksWrapper {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 2px solid var(--fontGrey);
        background-color: var(--white);
    }

    .dropdown--linksWrapper > a {
        width: 100%;
        height: 100%;
        color: var(--black);
        text-decoration: none;
        font-size: 1.4em;
        line-height: 2.2;
        text-align: center;
    }

    /* temponary */
    .mobNav--linkWrapper:nth-child(6) > p {
        color: var(--white);
        font-weight: bold;
    }
}

@media only screen and (max-width: 599px) {

    .navigation--wrapper {
        height:auto;
    }

    .hamburger {
        width: 40px;
        height: 40px;
    }

    .linkWrapper {
        height: 60px;
    }

    .mobNav--linkWrapper > a, 
    .mobNav--linkWrapper > p {
        font-size: 1.4em;
        line-height: 3;
    }

    .dropdown--linksWrapper > a {
        font-size: 1.2em;
        line-height: 2.6;
    }
} 