.home--wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    position: relative;
}

.home__mainConent {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.home__slider--wrapper {
    width: 100%;
    height: min-content;
    position: relative;
}

.home__slider--imgHolder {
    width: 100%;
}

.home__slider--imgHolder > img {
    width: 100%;
    object-fit: cover;
}

.home__slider--filtr {
    width: 100%;
    height: calc(100% - 4px);
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--blackTransp)
}

.home__services--mainWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -30px;
}

.home__services--wrapper {
    width: clamp(850px, 62.4%, 1600px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: nowrap;
}

.home__services--toilets,
.home__services--sanitation {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    background-color: var(--white);
}

.home__services--toilets {
    background-color: var(--white);
}

.home__services--sanitation {
    background-color: var(--white);
}

.home__services--sanitation:hover,
.home__services--toilets:hover {
    background-color: var(--hoverBackground);
    background-image: var(--hoverBackgroundImage);
}

.home__services--sanitation:hover .home__sanitation--linkHolder,
.home__services--toilets:hover .home__toilets--linkHolder {
    border: 3px solid var(--white);
    background-color: rgba(24, 88, 168, 0);
}

.home__services--sanitation:hover .home__sanitation--description:before,
.home__services--toilets:hover .home__toilets--description:before {
    border-top: 2px solid rgb(49, 100, 171);
    content: "";
    left: 2.5%;
    position: absolute;
    top: 0;
    width: 95%;
}

.home__services--sanitation:hover .home__sanitation--title > h1:first-of-type,
.home__services--toilets:hover .home__toilets--title > h1:first-of-type {
    color: var(--white);
}

.home__services--sanitation:hover .home__sanitation--description,
.home__services--toilets:hover .home__toilets--description {
    color: var(--white);
}

.home__toilets--header,
.home__sanitation--header {
    width: 100%;
    height: 250px;
    padding: 10px 35px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.home__toilets--imgHolder {
    width: 40%;
    max-width: 230px;
    display: flex;
    align-items: center;
}

.home__sanitation--imgHolder {
    width: 45%;
    display: flex;
    align-items: center;
}

.home__toilets--imgHolder > img,
.home__sanitation--imgHolder > img {
    width: 100%;
    object-fit: cover;
}

.home__toilets--title {
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home__sanitation--title {
    width: 50%;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home__toilets--title > h1,
.home__sanitation--title > h1 {
    font-family: 'Bebas Neue', cursive;
    font-size: clamp(47px, 3.5vw, 85px);
    font-weight: 100;
    white-space: nowrap;
    line-height: 1;
}

.home__toilets--title > h1:nth-child(2),
.home__sanitation--title > h1:nth-child(2) {
    color: var(--red);
}

.home__sanitation--title > h1 {
    color: var(--black);
}

.home__toilets--description,
.home__sanitation--description {
    width: 100%;
    padding: 25px 15px 15px 15px;
    margin-top: 10px;
    text-align: justify;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.home__toilets--description:before {
    content: '';
    width: 95%;
    border-top: 2px solid rgb(220, 220, 220);
    position: absolute;
    top: 0;
    left: 2.5%;
}

.home__sanitation--description:before {
    content: '';
    width: 95%;
    border-top: 2px solid rgb(220, 220, 220);
    position: absolute;
    top: 0;
    left: 2.5%;
}

.home__sanitation--description {
    color: var(--black);
}

.home__toilets--linkHolder,
.home__sanitation--linkHolder {
    width: 40%;
    margin: 10px 0 0 0;
    position: relative;
    bottom: 0;
    right: 0;
}

.home__toilets--linkHolder,
.home__sanitation--linkHolder {
    background-color: var(--red);
    border: 3px solid var(--red);

}

.home__toilets--linkHolder > a,
.home__sanitation--linkHolder > a {
    width: 100%;
    height: 100%;
    padding: 20px 15px;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    color: var(--white);
    font-weight: 500;
}

.home__benefits--wrapper {
    width: clamp(850px, 62.4%, 1600px);
}

@media only screen and (max-width: 949px) {

    .home__services--mainWrapper {
        position: relative;
        top: -20px;
    }

    .home__services--wrapper {
        width: 100%;
        justify-content:space-evenly
    }

    .home__toilets--header,
    .home__sanitation--header {
        flex-direction: column;
        justify-content:space-evenly;
        align-items: center;
    }

    .home__toilets--linkHolder,
    .home__sanitation--linkHolder {
        width: max-content;
    }

    .home__benefits--wrapper {
        width: 100%;
        padding: 0 15px;
    }
}   

@media only screen and (max-width: 599px) {

    .home__services--mainWrapper {
        position: static;
    }
    
    .home__services--wrapper {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
    }
    
    .home__toilets--title,
    .home__sanitation--title {
        width: 100%;
        padding: 10px 0 0 0;
        text-align: center;
    }

    .home__services--toilets, 
    .home__services--sanitation {
        width: 100%;
    }
    
    .home__toilets--header, 
    .home__sanitation--header {
        width: 100%;
        height: auto;
        padding: 10px;
    }

    .home__toilets--linkHolder > a, 
    .home__sanitation--linkHolder > a {
        font-size:0.7em;
    }

}




