
.form__formCabins--wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
}

.form__steps--first {
    width: 100%;
    aspect-ratio: 8;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 30px;
}

.form__steps--first > div {
    width: calc((100% / 6) - 3px);
    height: 100%;
    background-color: var(--grey2);
    position: relative;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2% 10px;
    text-align: center;    
}

.form__steps--first > div:nth-child(1) {
    background: var(--pageBackground);
    background: linear-gradient(90deg, var(--pageBackground) 25%, var(--grey2) 100%);
}

.form__steps--first > div:nth-child(6) {
    background: var(--pageBackground);
    background: linear-gradient(270deg, var(--pageBackground) 25%, var(--grey2) 100%);
}

.form__steps--first > div:nth-child(2)::after,
.form__steps--first > div:nth-child(3)::after,
.form__steps--first > div:nth-child(4)::after,
.form__steps--first > div:nth-child(5)::after {
    content: ' ';
    width: 15%;
    aspect-ratio: 1;
    background-color: var(--grey2);
    position: absolute;
    top: 40%;
    right: -8.5%;
    z-index: 1;
    transform: rotate(45deg);
    border-top: 3px solid var(--white);
    border-right: 3px solid var(--white);
}

.form__steps--first > div:nth-child(2),
.form__steps--first > div:nth-child(2)::after {
    background-color: var(--red);
    color: var(--white);
}

.form__steps--first > div > p:first-child {
    font-size: 30px;
    font-weight: bold;
}

.form__steps--first > div > p:last-child {
    font-size: clamp(12px, 1.1vw, 16px);
    white-space: nowrap;
}

.formCabins--form {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 30px 0  20px 0;
}

.formCabins--form > h3 {
    align-self: flex-start;
    font-size: 20px;
    font-weight: 600;
}

.formCabins--form > h3:after {
    content: ' MODELE KABIN:';
    font-size: 20px;
    font-weight: 600;
    color: var(--red);
}

.formCabins--cabinTypes {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
}

.formCabins--chooseType {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
}

.formCabins--chooseType > div {
    width: 50%;
    height: 90px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    position: relative;

    border-top: 1px solid var(--grey2);
    border-left: 1px solid var(--grey2);
    border-right: 1px solid var(--grey2);
}

.fusionContainer--filter,
.mtmContainer--filter {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--grey2Transp);
    z-index: 1;
}

.formCabins--chooseType > div > label {
    font-size: clamp(40px, 3vw, 50px);
    font-weight: 600;
}

.formCabins--chooseType > div:first-child > label::after {
    content: 'FUSION';
    font-size: clamp(40px, 3.5vw, 50px);
    font-weight: 600;
    color: var(--red);
}

.formCabins--chooseType > div:last-child > label::after {
    content: 'MTM 2.0';
    font-size: clamp(40px, 3.5vw, 50px);
    font-weight: 600;
    color: var(--red);
}

.chooseType__input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
}

.formCabins__Info--wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid var(--grey2);
    border-left: 1px solid var(--grey2);
    border-right: 1px solid var(--grey2);
    padding: 30px 0;
}

.formCabins__Info--carousel {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.prev,
.next {
    display: none;
}

.formCabins__Info--description {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 0 0 30px;
}

.formCabins__Info--description > h3 {
    font-size: 20px;
    font-weight: 600;
}

.cabinsForm--placeAndDate--wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.cabinsForm--place {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.cabinsForm--place > h3 {
    font-size: 20px;
    font-weight: 600;
    margin: 10px 0;
}

.cabinsForm--place > h3:after {
    content: '';
    font-size: 20px;
    font-weight: 600;
    color: var(--red);
}

.cabinsForm--place > h3:nth-of-type(1):after {
    content:'TOALET'
}

.cabinsForm--place > h3:nth-of-type(2):after {
    content:'DOSTARCZENIA'
}

.cabinsForm--place  input {
    height: 55px;
    border: 1px solid var(--grey2);
    border-radius: 10px;
    padding: 5%;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1.1em;
}

.input--phone {
    font-size: 1.1em !important;
}

.input--phone::placeholder {
    font-size: clamp(0.85em, 1vw, 1.1em);
    /* font-size: 0.85em; */
}

.cabinsForm--place > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.cabinsForm--place > div div {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.cabinsForm--place > div div:first-of-type input:first-of-type {
    width: 52%;
}

.cabinsForm--place > div div:first-of-type input:last-of-type {
    width: 46%;
}

.cabinsForm--place > div div:last-of-type input:first-of-type {
    width: 78%;
}

.cabinsForm--place > div div:last-of-type input:last-of-type {
    width: 20%;
}

.cabinsForm--date {
    width: 55%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.cabinsForm--date > h3 {
    font-size: 20px;
    font-weight: 600;
    margin: 10px 0;
}

.cabinsForm--date > h3:nth-of-type(1):after {
    content: 'TOALETY';
    font-size: 20px;
    font-weight: 600;
    color: var(--red);
}

.cabinsForm__date--wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
}

.cabinsForm__date--wrapper > div {
    width: 48%;
}

.cabinsForm__date--wrapper > div > h4 {
    font-size: 18px;
    margin: 0 0 30px 0;
}

.cabinsForm__serviceAndPrice--wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
}

.cabinsForm__serviceAndPrice--service {
    width: 50%;
}

.cabinsForm__serviceAndPrice--service > p {
    color: var(--red);
    font-weight: bold;
    margin-top: 10px;
}

.cabinsForm__service--inputs {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-top: 30px;
}

.cabinsForm__service--input {
    width: 32%;
    position: relative;
}

.cabinsForm__service--input > img {
    width: 100%;
}

.cabinsForm__service--input > input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
}

.cabinsForm__accesories--wrapper {
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.cabinsForm__accesories--wrapper > h3 {
    font-size: 20px;
    font-weight: 600;
    margin: 20px 0;
}

.cabinsForm__accesories--wrapper > h3::after {
    content: "DODATKOWE:";
    font-size: 20px;
    font-weight: 600;
    color: var(--red);
}

.cabinsForm__accesories--item {
    width: 100%;
    height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid var(--grey2);
    border-radius: 10px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1.1em;
    margin: 10px 0;
}

.cabinsForm__accesories--item > label {
    font-weight: normal;
}

.cabinsForm__accesories--input {
    height: 45px;
    aspect-ratio: 1;
    display: flex;
    justify-content:center;
    align-items: center;
    border-radius: 10px;
    margin: 0 20px 0 5px;
    position: relative;
}

.plusIcon,
.minusIcon {
    color: var(--white);
}

.cabinsForm__accesories--input > input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
}

.cabinsForm__price--wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
}

.cabinsForm__price--icon {
    width: 100px;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: var(--red);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
}

.cabinsForm__price--wrapper > h3 {
    font-size: 35px;
    font-weight: 600;
    margin: 20px 0;
    color: var(--red);
}

.cabinsForm__price--wrapper > h3::after {
    content: "DO ZAPŁATY:";
    font-size: 35px;
    font-weight: 600;
    color: var(--black);
}

.cabinsForm__price--price {
    width: 80%;
    height: 130px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    border: 1px solid var(--grey2);
    border-radius: 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1.1em;
    margin: 10px 0;
    background: var(--priceGradient);
}

.cabinsForm__price--price p:first-of-type {
    font-size: 2em;
    margin: 0 5px;
    font-weight: bold;
}

.cabinsForm__price--price p:last-of-type {
    font-size: 1.5em;
    font-weight: normal;
    white-space: nowrap;
}

.cabinsForm__price--price > div:first-of-type {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cabinsForm__price--price > div:last-of-type {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.cabinsForm__price--price > div > svg {
    color: var(--red);
}

@media only screen and (max-width: 949px) {

    .form__formCabins--wrapper {
        padding: 0 20px;
    }

    .form__steps--first > div:nth-child(1),
    .form__steps--first > div:nth-child(6) {
        display: none;
    }

    .form__steps--first > div {
        width: calc(25% - 3px);
    }

    .formCabins--chooseType > div {
        justify-content: center;
    }

    .formCabins--chooseType > div > label {
        width: 100%;
        text-align: center;
    }

    .formCabins--chooseType > div > svg {
        display: none;
    }

    .formCabins__Info--carousel {
        width: 100%;
    }

    .formCabins__Info--wrapper {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .cabinsForm--placeAndDate--wrapper,
    .cabinsForm__serviceAndPrice--wrapper,
    .cabinsForm__date--wrapper {
        flex-direction: column;
        gap: 20px;
    }

    .cabinsForm__date--wrapper {
        align-items: center;
        gap: 60px;
    }

    .cabinsForm__date--wrapper > div {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .cabinsForm__date--wrapper > div > h4 {
        align-self: flex-start;
    }

    .cabinsForm--place,
    .cabinsForm--date,
    .cabinsForm__serviceAndPrice--service {
        width: 100%;
    }

    .cabinsForm__price--wrapper {
        width: 100%;
        justify-content: center;
    }

    .cabinsForm--place  input,
    .input--phone {
    font-size: 1.5em !important;
    }
}

@media only screen and (max-width: 599px) {
   
    .formCabins--chooseType {
        flex-direction: column;
    }

    .formCabins--chooseType > div {
        width: 100%;
    }

    .carousel-container {
        max-height: 160px !important;
    }

    .thumbnails {
        display: none !important;
    }

    .formCabins__Info--description {
        width: 100%;
    }

    .cabinsForm--place input, 
    .input--phone {
        font-size: 0.6em !important;
    }

    .cabinsForm--place > div div {
        flex-direction: column;
        gap: 20px;
    }

    .cabinsForm--place > div div:first-of-type input,
    .cabinsForm--place > div div:last-of-type input {
        width: 100% !important;
    }

    .cabinsForm__accesories--item > label {
        font-size: 0.5em;
    }

    .cabinsForm__price--price {
        width: 100% !important;
    }

    .cabinsForm__price--price > div:first-of-type {
        display: none;
    }

    .cabinsForm__price--price > div:last-of-type {
        width: 100% !important;
    }

    .next__button--wrapper {
        justify-content: flex-end
    }

    .next__button--wrapper p {
        display: none;
    }
}






