:root {
    --red: rgb(237, 32, 36);
    --blue: rgb(24, 88, 168);
    --darkBlue: rgb(29, 63, 123);
    --blueHover: rgb(25, 102, 245);
    --black: rgb(0, 0, 0);
    --blackTransp: rgba(0, 0, 0, 0.65);
    --blackBaner: rgba(0, 0, 0, 0.9);
    --white: rgb(255, 255, 255);
    --whiteTransp: rgba(255, 255, 255, 0.7);
    --grey: rgb(35, 35, 35);
    --greyTransp: rgba(35, 35, 35, 0.8);
    --grey2: rgb(217, 217, 217);
    --grey2Transp: rgba(217, 217, 217, 0.7);
    --lightGreen: rgb(159, 231, 127);
    --green: rgb(132, 207, 98);
    --yellow: rgb(249, 249, 62);
    

    --pageBackground: rgb(251, 251, 251);
    --boxShadow: rgb(223 223 223);
    --navBackground: rgb(231, 230, 230);

    --fontGrey: rgb(66, 66, 66);
    --fontGrey2: rgb(124, 124, 124);

    --bellColor: rgb(228, 113, 73);
    --bellShadow: rgb(255, 199, 180);

    --greyGradient: linear-gradient(336deg, rgba(255,255,255,1) 3%, rgba(217,217,217,1) 70%);
    --priceGradient: linear-gradient(86deg, rgba(237,233,227,1) 25%, rgba(255,255,255,1) 50%);

    --hoverBackground: rgba(24, 88, 168, 1);
    --hoverBackgroundImage: linear-gradient(23deg, rgba(24, 88, 168, 1) 22%, rgba(29, 63, 123, 1) 60%);
}

        