.asenizationOrder--wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Roboto", sans-serif;
    position: relative;
}

.asenizationOrder--mainContent {
    width: clamp(850px, 62.4%, 1600px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    position: relative;
    margin: 30px 0;
}

.asenizationOrder--form {
    width: 100%;
}