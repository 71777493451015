.city--wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    position: relative;
}

.city--baner {
    width: 100%;
    position: relative;
}

.city--baner > img {
    width: 100%;
    object-fit: cover;
}

.city--baner__filter {
    width: 100%;
    height: calc(100% - 4px);
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--blackBaner);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.city--baner__filter > h1,
.city--baner__filter > h2 {
    font-family: 'Bebas Neue', cursive;
    font-size: clamp(45px, 4.6vw, 88px);
    font-weight: normal;
    color: var(--white);
}

.city--baner__filter > h1:after {
    content: 'TOALET.PL';
    color: var(--red);
}

.city--mainContent {
    width: clamp(850px, 62.4%, 1600px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    position: relative;
}

.city--imgWrapper {
    width: 100%; 
    margin-top: 20px;
    position: relative;
}

.city--imgWrapper > img {
    width: 100%;
    object-fit: cover;
}

.city--red_baner {
    width: 30%;
    position: absolute;
    bottom: -40px;
    right: 60px;
    font-size: 2em;
}

.city--description {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    margin: 80px 0;
}

.city--description > h3 {
    width: 100%;
    text-align: left;
}

.city--description > p {
    display: inline-block;
    text-align: justify;
    line-height: 2em;
    font-size: 1.1em;
    font-weight: 300;
    color: var(--fontGrey);
}

.city__mainContent--list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.city__mainContent--line {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.city__mainContent--line > img {
    width: 29px;
    height: 29px;
    object-fit: cover;
}

.city__mainContent--line > p {
    display: inline-block;
    width: calc(100% - 49px);
    font-size: 18px;
    font-weight: 500;
    text-align: justify;
    letter-spacing: 0.1em; 
    color: var(--fontGrey);
}

.city__benefits--wrapper {
    width: clamp(850px, 62.4%, 1600px);
}

.city__last_red_baner--wrapper {
    width: clamp(850px, 62.4%, 1600px);
    display: flex;
    justify-content: flex-end;
    margin: 40px 0 80px 0;
}

.city__last_red_baner--wrapper > div {
    width: 48%;
    font-size: 3.5em;
}

@media only screen and (max-width: 949px) {

    .city--baner__filter > h1,
    .city--baner__filter > h2 {
        font-size: 1.5em;
    }

    .city--baner > img {
        font-size: 1.5em;
    }

    .city--mainContent,
    .city__last_red_baner--wrapper {
        width: 100%;
    }

    .city--red_baner,
    .city__last_red_baner--wrapper {
        display: none;
    }

    .city__benefits--wrapper {
        width: 100%;
        padding: 0 15px;
    }

    .city--mainContent {
        width: 100%;
    }

    .city--description {
        width: 100%;
        padding: 0 15px;
    }

    .city__mainContent--list {
        gap: 10px;
    }

    .city__mainContent--line {
        gap: 10px;
        justify-content: flex-start;
    }

    .city__mainContent--line > img {
        width: 15px;
        height: 15px;
    }

    .city__mainContent--line > p {
        font-size: 0.8em;
        letter-spacing: 0;
    }
}

@media only screen and (max-width: 599px) {

    .city--baner__filter > h1, 
    .city--baner__filter > h2 {
        font-size: 0.9em;
    }

    .city--description {
        margin: 20px 0;
    }

    .city--description > h3 {
        font-size: 1em;
    }

    .city--description > p {
        font-size: 0.8em;
    }
}
