.formPersonalInfo--wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
}

.form__steps--secound {
    width: 100%;
    aspect-ratio: 8;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 30px;
}

.form__steps--secound > div {
    width: calc((100% / 6) - 3px);
    height: 100%;
    background-color: var(--grey2);
    position: relative;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2% 10px;
    text-align: center;    
}

.form__steps--secound > div:nth-child(1) {
    background: var(--pageBackground);
    background: linear-gradient(90deg, var(--pageBackground) 25%, var(--grey2) 100%);
}

.form__steps--secound > div:nth-child(6) {
    background: var(--pageBackground);
    background: linear-gradient(270deg, var(--pageBackground) 25%, var(--grey2) 100%);
}

.form__steps--secound > div:nth-child(2)::after,
.form__steps--secound > div:nth-child(3)::after,
.form__steps--secound > div:nth-child(4)::after,
.form__steps--secound > div:nth-child(5)::after {
    content: ' ';
    width: 15%;
    aspect-ratio: 1;
    background-color: var(--grey2);
    position: absolute;
    top: 40%;
    right: -8.5%;
    z-index: 1;
    transform: rotate(45deg);
    border-top: 3px solid var(--white);
    border-right: 3px solid var(--white);
}

.form__steps--secound > div:nth-child(3),
.form__steps--secound > div:nth-child(3)::after {
    background-color: var(--red);
    color: var(--white);
}

.form__steps--secound > div > p:first-child {
    font-size: 30px;
    font-weight: bold;
}

.form__steps--secound > div > p:last-child {
    font-size: clamp(12px, 1.1vw, 16px);
    white-space: nowrap;
}

.formPersonalInfo--form {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 30px 0  20px 0;
}

.formPersonalInfo__form--inputsWrapper,
.formPersonalInfo--orderSummary {
    width: 48%;
}

.formPersonalInfo__form--inputsWrapper > h3,
.formPersonalInfo--orderSummary > h3 {
    align-self: flex-start;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
}

.formPersonalInfo__form--inputsWrapper > h3:after {
    content: ' ZAMAWIAJĄCEGO:';
    font-size: 20px;
    font-weight: 600;
    color: var(--red);
}

.formPersonalInfo--orderSummary > h3:after {
    content: ' ZAMÓWIENIA:';
    font-size: 20px;
    font-weight: 600;
    color: var(--red);
}

.formPersonalInfo__inputsHolder {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 40px;
}

.formPersonalInfo__inputsHolder > div {
    width: max-content;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.formPersonalInfo__notCheckedIcon {
    color: var(--fontGrey2);
}

.formPersonalInfo__inputsHolder > div > input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.formPersonalInfo__inputsHolder > div > label {
    margin-left: 10px;
    font-size: 1.2em;
    font-weight: bold;
}

.formPersonalInfo__form--companyForm,
.formPersonalInfo__form--contactData {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.formPersonalInfo__form--companyForm input {
    height: 55px;
    border: 1px solid var(--grey2);
    border-radius: 10px;
    padding: 5%;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: clamp(0.85em, 1vw, 1.1em);
}

.formPersonalInfo__form--companyForm input:nth-child(1),
.formPersonalInfo__form--companyForm input:nth-child(2),
.formPersonalInfo__form--companyForm input:nth-child(8) {
    width: 100%;
}

.formPersonalInfo__form--companyForm input:nth-child(3) {
    width: 60%;
}

.formPersonalInfo__form--companyForm input:nth-child(4) {
    width: calc(40% - 20px);
}

.formPersonalInfo__form--companyForm input:nth-child(5) {
    width: 50%;
}

.formPersonalInfo__form--companyForm input:nth-child(6),
.formPersonalInfo__form--companyForm input:nth-child(7) {
    width: calc(25% - 20px);
    padding: 0 2%;
}

.formPersonalInfo__form--contactData {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    margin: 40px 0;
}

.formPersonalInfo__form--contactData > input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.formPersonalInfo__form--contactData > label {
    margin-left: 10px;
    font-size: 1.2em;
    font-weight: bold;
}

.formPersonalInfo__contactData {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 40px;
}

.formPersonalInfo__contactData input {
    height: 55px;
    border: 1px solid var(--grey2);
    border-radius: 10px;
    padding: 5%;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: clamp(0.85em, 1vw, 1.1em);
}

.formPersonalInfo__contactData input:nth-child(1) {
    width: 100%;
}

.formPersonalInfo__contactData input:nth-child(2) {
    width: 60%;
}

.formPersonalInfo__contactData input:nth-child(3) {
    width: calc(40% - 20px);
}

.formPersonalInfo__contactData input:nth-child(4) {
    width: 50%;
}

.formPersonalInfo__contactData input:nth-child(5),
.formPersonalInfo__contactData input:nth-child(6) {
    width: calc(25% - 20px);
    padding: 0 2%;
}

.formPersonalInfo--summary {
    width: 100%;
    padding: 6%;
    background-color: var(--white);
    border: 1px solid var(--grey2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 30px;
}

.formPersonalInfo--summary > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
}

.formPersonalInfo--summary > div > label {
    font-size: 1.5em;
    font-weight: bold;
}

.formPersonalInfo--summary > div > p {
    font-size: 1.2em;
}

.formPersonalInfo__summary--price {
    width: 100% !important;
    margin: 40px 0 20px 0;
    gap: 0px !important;
}

.formPersonalInfo__summary--price > h3 {
    align-self: flex-start;
    font-size: 2.5em;
    font-weight: 600;
    margin-bottom: 20px;
}

.formPersonalInfo__summary--price > h3:after {
    content: ' USŁUGI:';
    font-weight: 600;
    color: var(--red);
}

.formPersonalInfo__price--price {
    width: 80%;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--grey2);
    border-radius: 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1.1em;
    margin: 10px 0;
    background: var(--priceGradient);
}

.formPersonalInfo__price--price p:first-of-type {
    font-size: 2em;
    margin: 0 5px;
    font-weight: bold;
}

.formPersonalInfo__price--price p:last-of-type {
    font-size: 1.5em;
    font-weight: normal;
    white-space: nowrap;
}

.formPersonalInfo__price--price > div:first-of-type {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.formPersonalInfo__price--price > div:last-of-type {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.formPersonalInfo__price--price > div > svg {
    color: var(--red);
}

@media only screen and (max-width: 949px) {
    .formPersonalInfo--wrapper {
        padding: 0 20px;
    }

    .form__steps--secound > div:nth-child(1),
    .form__steps--secound > div:nth-child(6) {
        display: none;
    }

    .form__steps--secound > div {
        width: calc(25% - 3px);
    }

    .formPersonalInfo--form {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .formPersonalInfo__form--inputsWrapper, 
    .formPersonalInfo--orderSummary {
        width: 90%;
    }

}

@media only screen and (max-width: 599px) {

    .formPersonalInfo__inputsHolder {
        flex-direction: column;
        gap: 20px;
    }

    .formPersonalInfo__inputsHolder svg {
        width: 20px;
    }

    .formPersonalInfo__inputsHolder > div > label {
        font-size: 1em;
        word-wrap: break-word;
    }

    .formPersonalInfo__form--companyForm input,
    .formPersonalInfo__contactData input {
        width: 100% !important;
    }

    .formPersonalInfo__price--price > div:first-of-type {
        display: none;
    }

    .formPersonalInfo__price--price > div:last-of-type {
        width: 100% !important;
    }

    .formPersonalInfo__summary--price > h3 {
        text-align: center !important;
    }

    .formPersonalInfo__summary--price {
        align-items: center !important;
    }
}