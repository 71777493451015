.benefits--wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px 0;

}

.benefits--wrapper > h2 {
    font-size: 50px;
    font-weight: 400;
    color: var(--red);
}

.benefits__img--wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: 45px;

}

.benefits--box {
    width: 22%;
    aspect-ratio: 1;
    border: 2px solid var(--grey2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--white)
}

.benefits--box:hover {
    background-color: var(--hoverBackground);
    background-image: var(--hoverBackgroundImage);
}

.benefits__box--icon {
    width: 90%;
    aspect-ratio: 2;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.benefits__box--icon > img {
    height: 70%;
}

.benefits__box--description {
    width: 90%;
    aspect-ratio: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.benefits__box--description > span {
    font-size: clamp(9px, 0.9vw, 25px);
    color: var(--black);
}

.benefits--box:hover .benefits__box--description > span {
    color: var(--white);
}

@media only screen and (max-width: 949px) {

    .benefits__img--wrapper {
        margin-top: 20px;
    }

}

@media only screen and (max-width: 599px) {
    
    .benefits--wrapper > h2 {
        font-size: 10vw;
    }

    .benefits__img--wrapper {
        flex-wrap: wrap;
        justify-content: space-evenly;
        gap: 20px;
    }

    .benefits--box {
        width: 45%;
    }
}



