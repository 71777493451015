.sanitationService--contentWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
}

.sanitationService--form {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.sanitationService__form--type,
.sanitationService__form--addres {
    width: 48%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 20px 0;
}

.sanitationService__form--type > h3,
.sanitationService__form--addres > h3 {
    align-self: flex-start;
    font-size: 20px;
    font-weight: 600;
}

.sanitationService__form--type > h3:nth-last-of-type(2) {
    margin-top: 30px
}

.sanitationService__form--type > h3::after {
    content: ' NIECZYSTOŚCI';
    font-size: 20px;
    font-weight: 600;
    color: var(--red);
}

.sanitationService__form--type > h3:last-of-type::after {
    content: ' WYWOZU';
    font-size: 20px;
    font-weight: 600;
    color: var(--red);
}

.sanitationService__form--type > select,
.sanitationService__form--type > input {
    width: 100%;
    height: 55px;
    border: 1px solid var(--grey2);
    border-radius: 10px;
    font-size: 1.1em;
    font-weight: 500;
    text-align: center;
}



.sanitationService__form--addres {
    width: 48%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 20px 0;
}

.sanitationService__form--addres > div > input,
.sanitationService__form--addres > input {
    height: 55px;
    border: 1px solid var(--grey2);
    border-radius: 10px;
    padding: 5%;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1.1em;
}

.sanitationService__form--addres > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.sanitationService__form--addres > div:first-of-type input:first-of-type {
    width: 52%;
}

.sanitationService__form--addres > div:first-of-type input:last-of-type {
    width: 46%;
}

.sanitationService__form--addres > div:nth-child(3) input:first-of-type {
    width: 78%;
}

.sanitationService__form--addres > div:nth-child(3) input:last-of-type {
    width: 20%;
}

.sanitationService__form--map {
    width: 100%;
    aspect-ratio: 1;
    border-radius: 8px;
}

.emptyContainer {
    width: 48%;
    height: 1px;
}

.cabinsForm__price--wrapper {
    margin-top: 30px;
}