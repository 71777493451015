.formEnding--wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
}

.form__steps--fourth {
    width: 100%;
    aspect-ratio: 8;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 30px;
}

.form__steps--fourth > div {
    width: calc((100% / 6) - 3px);
    height: 100%;
    background-color: var(--grey2);
    position: relative;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2% 10px;
    text-align: center;    
}

.form__steps--fourth > div:nth-child(1) {
    background: var(--pageBackground);
    background: linear-gradient(90deg, var(--pageBackground) 25%, var(--grey2) 100%);
}

.form__steps--fourth > div:nth-child(6) {
    background: var(--pageBackground);
    background: linear-gradient(270deg, var(--pageBackground) 25%, var(--grey2) 100%);
}

.form__steps--fourth > div:nth-child(2)::after,
.form__steps--fourth > div:nth-child(3)::after,
.form__steps--fourth > div:nth-child(4)::after,
.form__steps--fourth > div:nth-child(5)::after {
    content: ' ';
    width: 15%;
    aspect-ratio: 1;
    background-color: var(--grey2);
    position: absolute;
    top: 40%;
    right: -8.5%;
    z-index: 1;
    transform: rotate(45deg);
    border-top: 3px solid var(--white);
    border-right: 3px solid var(--white);
}

.form__steps--fourth > div:nth-child(5),
.form__steps--fourth > div:nth-child(5)::after {
    background-color: var(--lightGreen);
    color: var(--black);
}

.form__steps--fourth > div > p:first-child {
    font-size: 30px;
    font-weight: bold;
}

.form__steps--fourth > div > p:last-child {
    font-size: clamp(12px, 1.1vw, 16px);
    white-space: nowrap;
}

.formEnding__content--wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0  20px 0;
}

.formEnding--content {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--green);
    border-radius: 8px;
    margin: 40px 0 80px 0;
}

.formEnding--content > p {
    font-size: clamp(43px, 3vw, 70px);
    
    font-weight: bold;
}

.formEnding__content--wrapper > p {
    font-size: clamp(21.5px, 1.5vw, 35px);
    font-weight: bold;
}

@media only screen and (max-width: 949px) {
    .formEnding--wrapper {
        padding: 0 20px;
    }

    .form__steps--fourth > div:nth-child(1),
    .form__steps--fourth > div:nth-child(6) {
        display: none;
    }

    .form__steps--fourth > div {
        width: calc(25% - 3px);
    }

    .formEnding__content--wrapper {
        margin: 0;
    }

    .formEnding--content > p,
    .formEnding__content--wrapper > p {
        font-size: 5vw;
        text-align: center;
    }

    .formEnding--content {
        margin: 40px 0;
    }
}