.footer--wrapper {
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: var(--grey); 
}

.footer--mainContainer {
    width: clamp(850px, 62.4%, 1600px);
    height: 80%;
    display: flex;
    flex-direction: row;
}

.footer--contactContainer {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
}

.footer--contactContainer img {
    width: 65%;
    object-fit: cover;
}

.footer--description {
    padding: 10px 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;
}

.footer--description p {
    color: var(--whiteTransp);
}

.footer--contactWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    padding: 0 8px;
    position: absolute;
    bottom: 0;
    left: 0;
}

.footer--media {
    width:max-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.footer--iconContainer {
    background-color: var(--whiteTransp);
    width: 28px;
    height: 28px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer--media p {
    color: var(--whiteTransp);
    font-size: 16px;
}


.footer--mapContainer {
    width: 55%;
    height: 100%;
    border-radius: 8px;
}

@media only screen and (max-width: 949px) {

    .footer--wrapper {
        height: 500px;
    }

    .footer--mainContainer {
        width: 100%;
        height: 100%;
        padding: 20px;
        flex-direction: column;
        align-items: center
    }

    .footer--contactContainer {
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .footer--contactContainer img {
        width: 60%;
    }

    .footer--description {
        width: 100%;
        padding: 0;
        justify-content: center;
    }

    .footer--contactWrapper {
        position: static;
        justify-content: space-evenly;
        margin: 20px 0;
    }

    .footer--mapContainer {
        width: 100%;
    }
}

@media only screen and (max-width: 599px) {

    .footer--contactContainer img {
        width: 80%;
    }
}

@media only screen and (max-width: 599px) {
    
    .footer--description {
        font-size: clamp(13px, 4vw, 20px);
        text-align: center;
    }
}