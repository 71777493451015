.header--wrapper {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    background-color: var(--grey);
}

.header--mainContainer {
    width: clamp(850px, 62.4%, 1600px);
    height: 100%;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.header__mediaIcons--wrapper {
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
}

.header__mediaIcons--container {
    background-color: var(--whiteTransp);
    width: 28px;
    height: 28px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header__mediaIcons--container > a {
    color: var(--grey);
    font-size: 24px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header__mediaIcons--container:nth-child(2) > a {
    font-size: 18px;
}

.header__contactIcons--wrapper {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
}

.header__contactIcons--container {
    width: max-content;
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    gap: 5px;
}

.header__contactIcons--iconContainer {
    background-color: var(--whiteTransp);
    width: 28px;
    height: 28px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header--contactIcon {
    color: var(--grey);
    font-size: 18px;
}

.phone{
    font-size: 16px;
}

.header__contactIcons--container > p {
    color: var(--whiteTransp);
    font-size: 16px;
}

@media only screen and (max-width: 949px) {

    .header--mainContainer {
        width: 100%;
        padding: 10px 20px;
    }
}

@media only screen and (max-width: 599px) {

    .header--wrapper {
        height: auto;
    }

    .header--mainContainer {
        width: 100%;
        padding: 10px;
        flex-direction: column;
    }

    .header__mediaIcons--wrapper {
        width: 100%;
        justify-content: space-evenly;
    }

    .header__contactIcons--wrapper {
        display: none;
    }
}