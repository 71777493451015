.copyright--wrapper {
    width: 100%;
    height: 6em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: var(--grey);
}

.copyright--wrapper p {
    color: var(--fontGrey2);
    font-size: 1em;
}

.copyright--wrapper h1 {
    font-family: 'Bebas Neue', cursive;
    font-size: 1.5em;
    font-weight: normal;
    color: var(--red);
}

.copyright--wrapper h1::after {
    content: "TOALET.pl";
    color: var(--white);
}

.copyright--wrapper a {
    color: var(--fontGrey2);
    font-size: 1em;
    text-decoration: none;
}

@media only screen and (max-width: 599px) {

    .copyright--wrapper {
        padding: 10px 0;
        height: auto;
        flex-wrap: wrap;
    }
}