.contact--wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    position: relative;
}

.contact--baner {
    width: 100%;
    position: relative;
}

.contact--baner > img {
    width: 100%;
    object-fit: cover;
}

.contact--baner__filter {
    width: 100%;
    height: calc(100% - 4px);
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--blackBaner);
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact--baner__filter > h1 {
    font-family: 'Bebas Neue', cursive;
    font-size: clamp(45px, 4.6vw, 88px);
    font-weight: normal;
    color: var(--white);
}

.contact--baner__filter > h1:after {
    content: ' KONTAKTOWY';
    color: var(--red);
}

.contact--mainContent {
    width: clamp(850px, 62.4%, 1600px);
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    position: relative;
}

.contact--mainContent > h3 {
    align-self: flex-start;
    font-size: 20px;
    font-weight: 600;
}

.contact--mainContent > h3:after {
    content: ' KONTAKTOWY';
    font-size: 20px;
    font-weight: 600;
    color: var(--red);
}

.contact__form--wrapper {
    width: 100%;
}

.contact__form--wrapper > form {
    width: 100%;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 30px;
    position: relative;
}

.contact__form--wrapper > form > input {
    width: 50%;
    height: 70px;
    border: 2px solid var(--grey2);
    border-radius: 8px;
    padding: 20px;
    font-family: 'Roboto', sans-serif;
    font-size: 1.3em;
    font-weight: 500;
    color: var(--fontGrey);
}

.contact__form--wrapper > form > textarea {
    width: 100%;
    height: 300px;
    border: 2px solid var(--grey2);
    border-radius: 8px;
    padding: 20px;
    font-family: 'Roboto', sans-serif;
    font-size: 1.3em;
    font-weight: 500;
    color: var(--fontGrey);
    overflow: auto;
    resize: none;
}

.contact__form--wrapper > form > input::placeholder,
.contact__form--wrapper > form > textarea::placeholder {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: var(--fontGrey2);
}

.contact--submitBtn {
    width: 20%;
    height: 60px;
    border: none;
    background-color: var(--red);
    font-family: 'Roboto', sans-serif;
    text-align: center;
    white-space: nowrap;
    color: var(--white);
    font-weight: 500;
    font-size: 25px;
    position: absolute;
    cursor: pointer;
    bottom: -30px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact__button--icon {
    font-size: 50px;
    margin-right: 5px;
}

.contact--sended {
    display: block;
    width: 400px;
    height: 200px;
    position: absolute;
    top: 50%;
    left: calc(50% - 200px);
    background-color: var(--red);
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact--sended  > p {
    text-align: center;
    white-space: nowrap;
    color: var(--white);
    font-weight: 500;
    font-size: 20px;
}

.contact--footer {
    width: clamp(850px, 62.4%, 1600px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 70px 0;
    gap: 30px;
}

.contact__footer--imgWrapper {
    width: 26%;
}

.contact__footer--imgWrapper > img {
    width: 100%;
    object-fit: cover;
}

.contact__media {
    width: max-content;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.contact__media--icon {
    font-size: 1.3em;
    font-weight: 500;
    text-align: center;
    color: var(--fontGrey2);
}

.contact__media > p {
    font-size: 1.1em;
    font-weight: 500;
    text-align: center;
    color: var(--fontGrey2);
    white-space: nowrap;
}

.contact__media > p:last-child {
    font-size: 0.8em;
}


.contact__map--wrapper {
    width: 100%;
    height: 400px;
}

@media only screen and (max-width: 949px) {

    .contact--baner__filter > h1 {
        font-size: 1.5em;
    }

    .contact__form--wrapper > form > input {
        width: 100%;
    }

    .contact--mainContent {
        width: 100%;
        padding: 0 15px;
    }

    .contact--footer {
        width: 100%;
        justify-content: center;
        padding: 0 10px;
        flex-wrap: wrap;
    }

    .contact__form--wrapper > form > input::placeholder,
    .contact__form--wrapper > form > textarea::placeholder {
    font-size: 0.8em;
}

    .contact--submitBtn {
        width: max-content;
        padding: 10px;
    }

    .mapContainer--container {
        border-radius: 0;
    }

    .contact__map--wrapper {
        width: 98%;
    }

    .contact--footer {
        margin: 20px 0;
    }

    .contact--sended {
        width: 80%;
    }

    .contact--sended  > p {
        font-size: 0.8em;
    }
}

@media only screen and (max-width: 599px) {

    .contact--mainContent > h3 {
        align-self: center;
        text-align: center;
    }

    .contact__footer--imgWrapper {
        width: 90%;
    }

    .contact__media > p {
        font-size: 0.8em;
    }

    
    .contact--submitBtn {
        width: 50%;
        padding: 10px;
        bottom: -80px;
    }
}

@media only screen and (max-width: 350px) {

    .contact__button--icon {
        display: none;
    }
}
